.port-container {
    display: flex;
    padding: 4rem 0 2rem 0;
    display: flex;
    justify-content: top;
    flex-direction: column;
    padding: 50px;
    margin-bottom:80px;

  }

  .port-container-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    
  }

  .port-container> h1 {
    align-items: flex-start;
    color: rgb(14, 87, 28);
    font-size: 26px;
    margin-top: 60px;
    width:60%;
    margin-left:50px;
    text-align:left;
    
  }

  .port-container-items > img {
    width: 360px;
    height: 200px;
    object-fit: cover;
   margin-left:20px;
  }

  .port-container-items > video {
    object-fit: cover;
    width: 60%;
    height: 95%;
    position: fixed;
    z-index: -1;
  }
  